import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import { Calendar, GithubIcon, LinkedinIcon, TwitterIcon } from 'lucide-react';
import ROJ from './assets/images/Ryan.jpg';
import Will from './assets/images/Will.jpg';

const MemberCard = ({ member, index }) => {
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: "-100px" });
  
    useEffect(() => {
      if (isInView) {
        controls.start('visible');
      }
    }, [controls, isInView]);
  
    return (
      <motion.div
        ref={ref}
        initial={{ scale: 1 }}
        animate={{ scale: 1 }}
        variants={{
          hidden: { opacity: 0, y: 50 },
          visible: { opacity: 1, y: 0, transition: { duration: 0.5, delay: index * 0.2 } },
        }}
        whileHover={{
          scale: [1, 1.05, 1.02, 1.05],
          transition: {
            duration: 3,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut"
          }
        }}
        whileTap={{ scale: 0.95 }}
        className="bg-white rounded-lg shadow-lg overflow-hidden font-sans"
      >
      <div className="p-6">
        <img
          src={member.image}
          alt={member.name}
          className="w-full h-64 object-cover rounded-lg mb-4"
        />
        <h3 className="text-2xl font-bold mb-2 text-gray-800 font-display">{member.name}</h3>
        <p className="text-gray-600 mb-4 font-mono">{member.role}</p>
        <div className="flex space-x-4">
          <a href={`https://github.com/${member.github}`} className="text-gray-800 hover:text-[#264274] transition-colors">
            <GithubIcon size={24} />
          </a>
          <a href={`https://twitter.com/${member.twitter}`} className="text-gray-800 hover:text-[#14C7C8] transition-colors">
            <TwitterIcon size={24} />
          </a>
          <a href={`https://linkedin.com/in/${member.linkedin}`} className="text-gray-800 hover:text-[#C1FF72] transition-colors">
            <LinkedinIcon size={24} />
          </a>
        </div>
      </div>
    </motion.div>
  );
};

const randomVelocity = (min, max) => Math.random() * (max - min) + min;

const BouncingLetters = ({ text }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const letterVariants = {
    normal: { x: 0, y: 0, transition: { type: "spring", stiffness: 100 } },
    scattered: () => ({
      x: randomVelocity(-150, 150),
      y: randomVelocity(-100, 100),
      transition: {
        type: "spring",
        stiffness: 20,
        damping: 100
      }
    }),
  };

  return (
    <div 
      className="flex justify-center relative w-full h-48"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {text.split('').map((letter, index) => (
        <motion.span
          key={index}
          initial="normal"
          animate={isHovered ? 'scattered' : 'normal'}
          variants={letterVariants}
          className="text-6xl font-bold inline-block mx-1 font-display text-white"
        >
          {letter}
        </motion.span>
      ))}
    </div>
  );
};

const LandingPage = () => {
  const teamMembers = [
    { 
      name: "ROJNFT", 
      role: "IRDFK", 
      github: "ROJNFT", 
      twitter: "ROJNFT", 
      linkedin: "ROJNFT", 
      image: ROJ
    },
    { 
      name: "The0nlyWill", 
      role: "Idfk", 
      github: "The0nlyWill", 
      twitter: "The0nlyWill", 
      linkedin: "The0nlyWill", 
      image: Will
    }, 
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#264274] via-[#14C7C8] to-[#C1FF72] text-white overflow-x-hidden">
      <main className="container mx-auto mt-16 px-4 py-16">
        <section id="about" className="mb-32">
          <motion.div 
            className="text-center"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <BouncingLetters text="Voltex" />
          </motion.div>
          <motion.p 
            className="text-xl text-center max-w-2xl mx-auto font-sans font-medium"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            Our team of wizards and unicorns are here to turn your wildest dreams into digital reality. Buckle up, it's going to be a wild ride!
          </motion.p>
        </section>

        <section id="team" className="mb-32">
          <h2 className="text-4xl font-bold mb-16 text-center font-display">Meet the Magicians</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {teamMembers.map((member, index) => (
              <MemberCard key={member.name} member={member} index={index} />
            ))}
          </div>
        </section>

        <section id="contact" className="mb-32">
        <h2 className="text-4xl font-bold mb-16 text-center font-display">Ready to Start the Adventure?</h2>
        <motion.div 
            className="max-w-md mx-auto bg-white p-8 rounded-lg shadow-lg flex flex-col items-center text-center"
            initial={{ scale: 1 }}
            animate={{ scale: 1 }}
            whileHover={{
            scale: [1, 1.05, 1.02, 1.05],
            transition: {
                duration: 3,
                repeat: Infinity,
                repeatType: "reverse",
                ease: "easeInOut"
            }
            }}
            whileTap={{ scale: 0.95 }}
        >
            <h3 className="text-2xl font-bold mb-4 text-gray-800 font-display">Schedule a Meeting</h3>
            <p className="text-gray-600 mb-4 font-sans font-medium">Let's discuss how we can bring your ideas to life!</p>
            <a 
            href="https://calendly.com/team-awesome/meeting" 
            className="inline-flex items-center justify-center bg-gradient-to-r from-[#264274] to-[#14C7C8] text-white px-6 py-3 rounded-lg text-lg font-semibold hover:from-[#14C7C8] hover:to-[#C1FF72] transition-all duration-300 font-sans font-black"
            >
            <Calendar className="mr-2" />
            Book a Time Slot
            </a>
        </motion.div>
        </section>
      </main>

      <footer className="bg-[#264274] bg-opacity-50 backdrop-blur-md py-8">
        <div className="container mx-auto px-4 text-center font-sans font-thin">
          <p>&copy; {new Date().getFullYear()} Voltex All rights reserved.</p>
          <p className="mt-2">Powered by imagination, caffeine, and a sprinkle of stardust.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;