import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://v1-backend-34378275fbd8.herokuapp.com'  // adjust this if your backend URL is different
});

instance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401 && error.response.data.tempToken) {
      // Store the temp token
      localStorage.setItem('tempToken', error.response.data.tempToken);
      
      // Redirect to login page
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export default instance;