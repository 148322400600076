import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../assets/images/logo.png';

function Navbar({ username, onLogout }) {
  const location = useLocation();
  const [showDashboardLink, setShowDashboardLink] = useState(true);

  useEffect(() => {
    let timeout;
    if (location.pathname === '/dashboard') {
      // Delay hiding the Dashboard link by 300ms to match the transition
      timeout = setTimeout(() => setShowDashboardLink(false), 300);
    } else {
      // Show the Dashboard link with a delay when leaving the /dashboard page
      timeout = setTimeout(() => setShowDashboardLink(true), 300);
    }

    // Clean up the timeout when the component unmounts or pathname changes
    return () => clearTimeout(timeout);
  }, [location.pathname]);

  return (
    <div className="py-4 w-full max-w-lg flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-12 w-auto" />
        </Link>
        <Link
          to="/"
          className="text-xl font-bold text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white"
        >
          Voltex
        </Link>
      </div>

      <div className="flex items-center space-x-4">
        {username ? (
          <>
            {showDashboardLink && (
              <Link
                to="/dashboard"
                className="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white"
              >
                Dashboard
              </Link>
            )}
            <button
              onClick={onLogout}
              className="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white"
            >
              Logout
            </button>
          </>
        ) : (
          <>
            <Link
              to="/login"
              className="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white"
            >
              Login
            </Link>
            <Link
              to="/register"
              className="text-gray-700 dark:text-gray-300 hover:text-black dark:hover:text-white"
            >
              Register
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export default Navbar;
