import React, { useState, useEffect, useRef } from 'react';
import LoginPopup from './LoginPopup';
import Filters from './Filters';
import axios from '../utils/axiosConfig';

function Tracker() {
  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedSendVia, setSelectedSendVia] = useState('');
  const [error, setError] = useState('');
  const filterRef = useRef(null);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setShowFilters(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  const handleSelectionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setInputValue('');
    setError('');
  };

  const handleSendViaClick = (sendVia) => {
    setSelectedSendVia(sendVia);
    setShowFilters(true);
  };

  const validateAndExtractTwitchUsername = (input) => {
    const trimmedInput = input.trim();

    if (trimmedInput.startsWith('http://') || trimmedInput.startsWith('https://')) {
      try {
        const url = new URL(trimmedInput);
        const pathParts = url.pathname.split('/').filter(Boolean);
        if (url.hostname === 'www.twitch.tv' && pathParts.length > 0) {
          return pathParts[0].toLowerCase();
        } else {
          throw new Error('Invalid Twitch URL');
        }
      } catch (err) {
        console.log("Error: ", err);
        throw new Error('Invalid URL');
      }
    } else {
      if (/^[a-zA-Z0-9_]{4,25}$/.test(trimmedInput)) {
        return trimmedInput.toLowerCase();
      } else {
        throw new Error('Invalid Twitch username');
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setError('');
  };

  const handleButtonClick = async () => {
    if (!inputValue.trim()) {
      setError('You must enter a value before adding.');
      return;
    }

    let validatedInput = inputValue;

    if (selectedOption === 'twitch') {
      try {
        validatedInput = validateAndExtractTwitchUsername(inputValue);
      } catch (err) {
        setError(err.message);
        return;
      }
    }

    if (selectedOption === 'instagram') {
      try {
        const response = await axios.post(`${API_URL}/verify-instagram-account`, { handle: validatedInput });
        if (!response.data.exists) {
          setError('Instagram user does not exist.');
          return;
        }
      } catch (err) {
        console.error('Error verifying Instagram account:', err);
        setError('Failed to verify Instagram account. Please try again.');
        return;
      }
    }

    setError('');
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setShowPopup(true);
        return;
      }

      const filters = JSON.parse(localStorage.getItem('filters')) || {};
      
      if (selectedOption === 'stock') {
        const response = await axios.post(`${API_URL}/add-stock-tracker`, {
          ticker: validatedInput,
          preferences: filters[selectedOption]
        }, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.status === 201) {
          alert('Stock tracker added successfully');
          localStorage.removeItem('filters');
          setInputValue('');
        } else {
          alert('Failed to add stock tracker: ' + response.status);
        }
      } else {
        // Existing code for other tracker types
        let newTracker = {
          [selectedOption]: [{
            [selectedOption === 'wallet' ? 'walletAddress' : 
             selectedOption === 'pumpfun' ? 'coinname' : 'socialaccount']: validatedInput,
            ...filters[selectedOption]
          }]
        };
  
        const response = await axios.post(`${API_URL}/tracker`, newTracker, {
          headers: { Authorization: `Bearer ${token}` },
        });
  
        if (response.status === 201) {
          alert('Tracker added successfully');
          localStorage.removeItem('filters');
          setInputValue('');
        } else {
          alert('Failed to add tracker: ' + response.status);
        }
      }
    } catch (err) {
      console.error('Error adding tracker:', err);
      setError('Failed to add tracker. Please try again.');
    }
  };

  
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="mt-8 flex flex-col lg:flex-row lg:relative items-center lg:items-start">
      <div className="border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 shadow-md rounded p-4 w-full max-w-lg text-center text-black dark:text-white">
        <h2 className="text-lg font-semibold mb-4">Track</h2>
        <div className="mb-4">
          <select
            className="w-full border border-gray-300 dark:border-gray-600 rounded p-2 bg-white dark:bg-gray-800 text-black dark:text-white"
            value={selectedOption}
            onChange={handleSelectionChange}
          >
            <option value="">Select type</option>
            {/* <option value="wallet">Wallet</option>
            <option value="pumpfun">Pumpfun</option>
            <option value="twitter">Twitter</option> */}
            <option value="stock">Stock</option>
            <option value="twitch">Twitch.tv</option>
            <option value="kick">Kick.com</option>
            <option value="rumble">Rumble.com</option>
            <option value="instagram">Instagram</option>
          </select>
        </div>
        {selectedOption && (
          <div className="mb-4">
            <label className="block text-gray-700 dark:text-gray-400">
              {selectedOption === 'wallet' ? 'Wallet Address' :
               selectedOption === 'stock' ? 'Stock Ticker' :
               selectedOption === 'pumpfun' ? 'Coin Name or Address' :
               selectedOption === 'twitch' ? 'Twitch Username or URL' :
               'Username'}
            </label>
            <input
              type="text"
              className={`w-full border rounded p-2 bg-white dark:bg-gray-800 text-black dark:text-white ${error ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'}`}
              value={inputValue}
              onChange={handleInputChange}
              placeholder={selectedOption === 'twitch' ? 'Enter Twitch username or URL' : ''}
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
          </div>
        )}
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-gray-400">Send via</label>
          <div className="flex flex-col space-y-2">
            {['Discord', 'Telegram', 'SMS', 'WhatsApp'].map((sendVia) => (
              <button
                key={sendVia}
                onClick={() => handleSendViaClick(sendVia)}
                className="bg-gray-200 dark:bg-gray-700 p-2 rounded text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600 border border-gray-400 dark:border-transparent"
              >
                {sendVia}
              </button>
            ))}
          </div>
        </div>
        <button
          onClick={handleButtonClick}
          className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 border border-gray-400 dark:border-transparent"
        >
          Add
        </button>
      </div>

      {showFilters && (
        <div ref={filterRef} className="lg:absolute lg:left-full mt-4 lg:mt-0 lg:ml-4">
          <Filters
            selectedOption={selectedOption}
            selectedSendVia={selectedSendVia}
            onClose={() => setShowFilters(false)}
          />
        </div>
      )}

      {showPopup && <LoginPopup onClose={handleClosePopup} />}
    </div>
  );
}

export default Tracker;