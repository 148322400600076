import React from 'react';

function LoginPopup({ onClose }) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg max-w-sm w-full text-center">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Login Required</h2>
        <p className="text-gray-700 dark:text-gray-300 mb-4">This feature is only available for logged-in users.</p>
        <div className="space-x-4">
          <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">Login</button>
          <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600">Register</button>
        </div>
        <button
          onClick={onClose}
          className="mt-4 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default LoginPopup;
