import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditTrackerModal from './EditTrackerModal';

const CoinsIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="8" cy="8" r="6" />
      <path d="M18.09 10.37A6 6 0 1 1 10.34 18" />
      <path d="M7 6h1v4" />
      <path d="m16.71 13.88.7.71-2.82 2.82" />
    </svg>
  );
  
  const FanIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10.827 16.379a6.082 6.082 0 0 1-8.618-7.002l5.412 1.45a6.082 6.082 0 0 1 7.002-8.618l-1.45 5.412a6.082 6.082 0 0 1 8.618 7.002l-5.412-1.45a6.082 6.082 0 0 1-7.002 8.618l1.45-5.412Z" />
      <path d="M12 12v.01" />
    </svg>
  );
  
  // const LogInIcon = (props) => (
  //   <svg
  //     {...props}
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="24"
  //     height="24"
  //     viewBox="0 0 24 24"
  //     fill="none"
  //     stroke="currentColor"
  //     strokeWidth="2"
  //     strokeLinecap="round"
  //     strokeLinejoin="round"
  //   >
  //     <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4" />
  //     <polyline points="10 17 15 12 10 7" />
  //     <line x1="15" x2="3" y1="12" y2="12" />
  //   </svg>
  // );
  const VideoIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M14 9.5L21 6v12l-7-3.5v-5z" />
      <rect width="14" height="14" x="3" y="5" rx="2" ry="2" />
    </svg>
  );
  
  const WalletIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21 12V7H5a2 2 0 0 1 0-4h14v4" />
      <path d="M3 5v14a2 2 0 0 0 2 2h16v-5" />
      <path d="M18 12a2 2 0 0 0 0 4h4v-4Z" />
    </svg>
  );
  
  const QuestionMarkIcon = (props) => (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
      <path d="M12 17h.01" />
    </svg>
  );

  const renderTrackerType = (type) => {
    if (!type) {
      console.warn('Tracker type is undefined');
      return <QuestionMarkIcon className="w-6 h-6 text-gray-600 dark:text-gray-300/75" />;
    }
  
    switch (type.toLowerCase()) {
      case 'stock':
        return <CoinsIcon className="w-6 h-6 text-gray-600 dark:text-gray-300/75" />;
      case 'twitter':
      case 'instagram':
        return <FanIcon className="w-6 h-6 text-gray-600 dark:text-gray-300/75" />;
      case 'twitch':
      case 'rumble':
      case 'kick':
        return <VideoIcon className="w-6 h-6 text-gray-600 dark:text-gray-300/75" />;
      case 'wallet':
        return <WalletIcon className="w-6 h-6 text-gray-600 dark:text-gray-300/75" />;
      default:
        console.warn(`Unknown tracker type: ${type}`);
        return <QuestionMarkIcon className="w-6 h-6 text-gray-600 dark:text-gray-300/75" />;
    }
  };

  const getPlatformColor = (platform) => {
    switch (platform?.toLowerCase()) {
      case 'discord':
        return 'bg-purple-100 dark:bg-purple-900/50 text-purple-600 dark:text-purple-400';
      case 'telegram':
        return 'bg-blue-100 dark:bg-blue-900/50 text-blue-600 dark:text-blue-400';
      case 'whatsapp':
        return 'bg-green-100 dark:bg-green-900/50 text-green-600 dark:text-green-400';
      case 'sms':
        return 'bg-cyan-100 dark:bg-cyan-900/50 text-cyan-600 dark:text-cyan-400';
      default:
        return 'bg-gray-100 dark:bg-gray-800 text-gray-600 dark:text-gray-400';
    }
  };

  const TrackingItem = ({ tracker, onDelete, onEdit }) => {
    const displayName = tracker?.displayName || 'Unknown';
    const platform = tracker?.displayPlatform;
    const type = tracker?.displayType;
  
    if (!tracker || !type) {
      console.error('Invalid tracker data:', tracker);
      return null;
    }
  
    const getFilters = () => {
      if (['twitch', 'rumble', 'kick'].includes(type.toLowerCase())) {
        return tracker.sendupdates ? ['Send Updates'] : [];
      }
      if (!platform || !tracker[platform] || !tracker[platform][0]) return [];
      const platformData = tracker[platform][0];
      return Object.entries(platformData)
        .filter(([key, value]) => value === true && key !== 'sendupdates')
        .map(([key]) => key);
    };
  
    const filters = getFilters();
  
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 relative group border border-gray-200 dark:border-gray-700">
        <div className="flex justify-between items-center mb-2">
          <div className="flex items-center">
            <div className="w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center mr-2">
              {renderTrackerType(type)}
            </div>
            <span className="font-semibold text-gray-900 dark:text-white">{displayName}</span>
          </div>
          {platform && (
            <span className={`ml-2 px-2 py-1 rounded-full text-xs font-medium ${getPlatformColor(platform)}`}>
              {platform}
            </span>
          )}
        </div>
        <div className="text-sm text-gray-600 dark:text-gray-400">
          <p>Type: {type}</p>
          {filters.length > 0 && <p>Filters: {filters.join(', ')}</p>}
        </div>
        <div className="mt-2">
          {!['twitch', 'rumble', 'kick'].includes(type.toLowerCase()) && (
            <button 
              className="text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-300 focus:outline-none text-sm mr-2" 
              onClick={() => onEdit(tracker)}
            >
              Edit
            </button>
          )}
          <button 
            className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300 focus:outline-none text-sm" 
            onClick={() => onDelete(tracker.id)}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };
  
  // Update DeleteConfirmationModal
  const DeleteConfirmationModal = ({ onConfirm, onCancel }) => {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-sm w-full">
          <h2 className="text-xl font-bold mb-4 text-gray-900 dark:text-white">Delete Tracker</h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">Are you sure you want to delete this tracker?</p>
          <div className="flex justify-end space-x-2">
            <button
              className="px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 dark:bg-red-600 dark:hover:bg-red-700"
              onClick={onConfirm}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  };

const Dashboard = () => {
  const [trackers, setTrackers] = useState([]);
  const [error, setError] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [editingTracker, setEditingTracker] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
  const navigate = useNavigate();

  const formatTrackers = useCallback((data) => {
    if (!data || typeof data !== 'object') {
      console.error('Invalid data format received:', data);
      return [];
    }
  
    const formattedTrackers = [];
  
    Object.entries(data).forEach(([type, trackers]) => {
      if (type === 'username' || !Array.isArray(trackers)) return;
  
      trackers.forEach(tracker => {
        if (!tracker) return;
  
        const platform = ['discord', 'telegram', 'sms', 'whatsapp'].find(p => 
          tracker[p] && Array.isArray(tracker[p]) && tracker[p].length > 0
        );
  
        if (!platform) return;
  
        const name = tracker.walletid || tracker.ticker || tracker.socialaccount;
        
        if (!name) return;
  
        formattedTrackers.push({
          id: tracker.id,
          displayName: name,
          displayType: type,
          displayPlatform: platform,
          [platform]: tracker[platform],
          ...tracker
        });
      });
    });
  
    return formattedTrackers;
  }, []); // Empty dependency array since it doesn't use any state or props

  const fetchTrackers = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/tracker`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      
      if (response.status === 200 && response.data) {
        const formattedTrackers = formatTrackers(response.data);
        setTrackers(formattedTrackers);
        setError(null);
        return formattedTrackers;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error fetching trackers:', error);
      setError('Failed to fetch trackers. Please try again later.');
      return [];
    }
  }, [formatTrackers]); 

  useEffect(() => {
    fetchTrackers();
  }, [fetchTrackers]);

  const handleDelete = async (trackerId) => {
    try {
      console.log('Deleting tracker with ID:', trackerId);
      const response = await axios.delete(`${API_URL}/tracker/${trackerId}`, {
        headers: { 
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.status === 200) {
        setTrackers(prevTrackers => prevTrackers.filter(tracker => tracker.id !== trackerId));
        setDeleteConfirmation(null);
        setError(null);
      }
    } catch (error) {
      console.error('Error deleting tracker:', error.response?.data || error.message);
      setError('Failed to delete tracker. Please try again later.');
    }
  };

  const handleEdit = (tracker) => {
    console.log('Editing tracker:', tracker);
    setEditingTracker(tracker);
  };

  const handleSaveEdit = async (updatedTracker) => {
    try {
      console.log('Saving updated tracker:', updatedTracker);
      
      if (!updatedTracker.displayType || !updatedTracker.displayPlatform) {
        console.error('Missing required fields:', updatedTracker);
        setError('Invalid tracker data');
        return;
      }
  
      const updatePayload = {
        [updatedTracker.displayPlatform]: [{
          account: '',
          sendupdates: true,
          ...(updatedTracker[updatedTracker.displayPlatform]?.[0] || {})
        }]
      };
  
      // Add type-specific fields
      switch (updatedTracker.displayType.toLowerCase()) {
        case 'wallet':
          updatePayload.walletAddress = updatedTracker.displayName;
          break;
        case 'stock':
          updatePayload.ticker = updatedTracker.displayName;
          break;
        case 'twitter':
        case 'instagram':
        case 'twitch':
        case 'rumble':
        case 'kick':
          updatePayload.socialaccount = updatedTracker.displayName;
          break;
        default:
          console.warn(`Unknown tracker type: ${updatedTracker.displayType}`);
      }
  
      console.log('Update payload:', updatePayload);
  
      const response = await axios.put(
        `${API_URL}/tracker/${updatedTracker.id}`,
        updatePayload,
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      if (response.status === 200) {
        // Update the local state with the new tracker data
        setTrackers(prevTrackers => 
          prevTrackers.map(t => 
            t.id === updatedTracker.id ? {
              ...updatedTracker,
              [updatedTracker.displayPlatform]: updatePayload[updatedTracker.displayPlatform]
            } : t
          )
        );
        setEditingTracker(null);
        setError(null);
      }
    } catch (error) {
      console.error('Error updating tracker:', error);
      setError('Failed to update tracker. Please try again later.');
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <>
    <header className="p-6 border-b border-gray-300 dark:border-gray-700 relative">
    <div className="flex items-center justify-center relative">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white text-center w-full">
        Trackers Dashboard
        </h1>
    </div>
    
    {/* "Go back" button on the right */}
    <div className="absolute right-0 top-6 group">
        <button
        onClick={() => navigate('/track')}
        className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700 transition-colors duration-200"
        aria-label="Go back to Tracker Page"
        >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-gray-600 dark:text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
            />
        </svg>
        </button>

        {/* Tooltip */}
        <div className="absolute right-0 mt-2 invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-all duration-200 bg-gray-800 dark:bg-gray-700 text-white px-2 py-1 rounded text-sm whitespace-nowrap">
        Go back to Tracker Page
        </div>
    </div>
    </header>
      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {trackers.map(tracker => (
            <TrackingItem
              key={tracker.id}
              tracker={tracker}
              onDelete={() => setDeleteConfirmation(tracker.id)}
              onEdit={() => handleEdit(tracker)}
            />
          ))}
        </div>
      </div>
      {deleteConfirmation && (
        <DeleteConfirmationModal
          onConfirm={() => handleDelete(deleteConfirmation)}
          onCancel={() => setDeleteConfirmation(null)}
        />
      )}
      {editingTracker && (
        <EditTrackerModal
          tracker={editingTracker}
          onClose={() => setEditingTracker(null)}
          onSave={handleSaveEdit}
        />
      )}
    </>
  );
};

export default Dashboard;