import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { debounce } from 'lodash';
import Navbar from './components/Navbar';
import Tracker from './components/Tracker';
import DarkModeToggle from './components/DarkModeToggle';
import Login from './components/Login';
import Register from './components/Register';
import DashboardComponent from './components/Dashboard';
import LandingPage from './LandingPage.js';
import axios from 'axios';

function App() {
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [darkMode, setDarkMode] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

  const checkLoginStatus = useCallback(() => {
    const storedToken = localStorage.getItem('token');
    const loginTimestamp = localStorage.getItem('loginTimestamp');
    const currentTime = new Date().getTime();
    
    if (storedToken && loginTimestamp && (currentTime - parseInt(loginTimestamp) < 2 * 60 * 60 * 1000)) {
      return storedToken;
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('loginTimestamp');
      return null;
    }
  }, []);

  const setLoginStatus = useCallback((newToken) => {
    if (newToken) {
      localStorage.setItem('token', newToken);
      localStorage.setItem('loginTimestamp', new Date().getTime().toString());
      setToken(newToken);
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('loginTimestamp');
      setToken('');
    }
  }, []);

  const updateDarkMode = useCallback((newDarkMode) => {
    setDarkMode(newDarkMode);
    localStorage.setItem('darkMode', JSON.stringify(newDarkMode));
    if (newDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    const verifyToken = async (storedToken) => {
      try {
        const response = await axios.post(`${API_URL}/verify-token`, {}, {
          headers: { Authorization: `Bearer ${storedToken}` },
        });
        setUsername(response.data.username);
        updateDarkMode(response.data.darkMode);
        setLoginStatus(storedToken);
      } catch (err) {
        console.error('Token verification failed:', err);
        setLoginStatus(null);
      }
    };
  
    const storedToken = checkLoginStatus();
    if (storedToken) {
      verifyToken(storedToken);
    } else {
      const savedDarkMode = localStorage.getItem('darkMode');
      if (savedDarkMode !== null) {
        updateDarkMode(JSON.parse(savedDarkMode));
      }
    }
  }, [API_URL, checkLoginStatus, setLoginStatus, updateDarkMode]);

  const handleLogin = (newToken, username, darkMode) => {
    setLoginStatus(newToken);
    setUsername(username);
    updateDarkMode(darkMode);
  };

  const handleLogout = () => {
    setLoginStatus(null);
    setUsername('');
    localStorage.removeItem('darkMode');
    updateDarkMode(false);
  };

  const handleRegister = (newToken, username) => {
    setLoginStatus(newToken);
    setUsername(username);
  };

  const debouncedSaveDarkMode = useMemo(
    () => debounce(async (newDarkMode, token, API_URL) => {
      if (!token) return;
      try {
        const response = await fetch(`${API_URL}/save-dark-mode`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify({ darkMode: newDarkMode }),
        });
    
        if (!response.ok) {
          throw new Error('Failed to save dark mode preference');
        }
      } catch (error) {
        console.error('Error saving dark mode preference:', error);
      }
    }, 300),
    []
  );

  const saveDarkModePreference = useCallback((newDarkMode) => {
    debouncedSaveDarkMode(newDarkMode, token, API_URL);
  }, [debouncedSaveDarkMode, token, API_URL]);

  const toggleDarkMode = useCallback(() => {
    const newDarkMode = !darkMode;
    updateDarkMode(newDarkMode);
    if (token) {
      saveDarkModePreference(newDarkMode);
    }
  }, [darkMode, updateDarkMode, saveDarkModePreference, token]);

  return (
    <Router>
      <AppContent
        token={token}
        username={username}
        darkMode={darkMode}
        handleLogout={handleLogout}
        toggleDarkMode={toggleDarkMode}
        onLogin={handleLogin}
        onRegister={handleRegister}
      />
    </Router>
  );
}

function AppContent({ token, username, darkMode, handleLogout, toggleDarkMode, onLogin, onRegister }) {
  const location = useLocation();
  const nodeRef = useRef(null);
  const [isContentVisible, setIsContentVisible] = useState(true);
  const showNavbar = location.pathname !== '/';

  return (
    <div className={`min-h-screen ${darkMode ? 'dark bg-gray-900' : 'bg-white'} transition-all duration-300`}>
      {showNavbar && (
        <>
          <div className="absolute top-4 right-4">
            <DarkModeToggle toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
          </div>
          
          <div className="w-full max-w-lg mx-auto">
            <Navbar username={username} onLogout={handleLogout} />
          </div>
        </>
      )}

      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames="fade"
          timeout={300}
          nodeRef={nodeRef}
          onExit={() => setIsContentVisible(false)}
          onExited={() => setIsContentVisible(true)}
        >
          <div ref={nodeRef}>
            {isContentVisible && (
              <Routes location={location}>
                <Route 
                  path="/" 
                  element={<LandingPage />} 
                />
                <Route 
                  path="/track" 
                  element={
                    <div className="w-full max-w-lg mx-auto">
                      {token ? <Tracker /> : <Navigate to="/login" replace />}
                    </div>
                  } 
                />
                <Route 
                  path="/dashboard" 
                  element={
                    token ? (
                      <div className="flex justify-center w-full">
                        <div className="w-[75%] bg-white dark:bg-gray-900 rounded-lg shadow-lg m-8 transition-colors duration-300">
                          <DashboardComponent />
                        </div>
                      </div>
                    ) : (
                      <Navigate to="/login" replace />
                    )
                  } 
                />
                <Route 
                  path="/login" 
                  element={
                    <div className="w-full max-w-lg mx-auto">
                      <Login onLogin={onLogin} />
                    </div>
                  } 
                />
                <Route 
                  path="/register" 
                  element={
                    <div className="w-full max-w-lg mx-auto">
                      <Register onRegister={onRegister} />
                    </div>
                  } 
                />
              </Routes>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
}

export default App;
