import React, { useState, useEffect } from 'react';

const EditTrackerModal = ({ tracker, onClose, onSave }) => {
  const [filters, setFilters] = useState({});
  const platform = tracker.displayPlatform?.toLowerCase();

  useEffect(() => {
    // Initialize filters based on the tracker's current platform settings
    if (tracker && platform && tracker[platform]?.[0]) {
      setFilters(tracker[platform][0]);
    }
  }, [tracker, platform]);

  const handleSave = () => {
    const updatedTracker = {
      ...tracker,
      [platform]: [{
        ...(tracker[platform]?.[0] || {}),
        ...filters,
        sendupdates: true
      }]
    };

    onSave(updatedTracker);
  };

  const renderFilterOptions = () => {
    switch (tracker.displayType.toLowerCase()) {
      case 'wallet':
        return (
          <>
            <FilterCheckbox label="Buys" name="buys" checked={filters.buys} onChange={handleFilterChange} />
            <FilterCheckbox label="Sells" name="sells" checked={filters.sells} onChange={handleFilterChange} />
            <FilterCheckbox label="Swaps" name="swaps" checked={filters.swaps} onChange={handleFilterChange} />
          </>
        );
      case 'stock':
        return (
          <>
            <FilterCheckbox label="Buys" name="buys" checked={filters.buys} onChange={handleFilterChange} />
            <FilterCheckbox label="Sells" name="sells" checked={filters.sells} onChange={handleFilterChange} />
            <FilterCheckbox label="Price Drop" name="pricedrop" checked={filters.pricedrop} onChange={handleFilterChange} />
            <FilterCheckbox label="Price Raise" name="priceraise" checked={filters.priceraise} onChange={handleFilterChange} />
          </>
        );
      default:
        return null;
    }
  };

  const handleFilterChange = (name, checked) => {
    setFilters(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 w-96">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold dark:text-white">Edit {tracker.displayType} Tracker</h2>
        </div>

        <div className="mb-2">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Name: {tracker.displayName}
          </p>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Platform: {tracker.displayPlatform}
          </p>
        </div>

        <div className="mb-4">
          <p className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Filters</p>
          <div className="space-y-2">
            {renderFilterOptions()}
          </div>
        </div>

        <div className="flex justify-end space-x-2">
          <button
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

const FilterCheckbox = ({ label, name, checked, onChange }) => {
  return (
    <label className="flex items-center space-x-2">
      <input
        type="checkbox"
        checked={checked || false}
        onChange={(e) => onChange(name, e.target.checked)}
        className="rounded border-gray-300 dark:border-gray-600"
      />
      <span className="text-sm text-gray-700 dark:text-gray-300">{label}</span>
    </label>
  );
};

export default EditTrackerModal;