import React, { useState, useEffect, forwardRef } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const Filters = forwardRef(({ selectedOption, selectedSendVia, onClose }, ref) => {
  const [account, setAccount] = useState('');
  const [error, setError] = useState('');

  // General filters
  //const [selectedSendUpdates, setSelectedSendUpdates] = useState(true);

  // Filter states for different platforms
  const [selectedBuys, setSelectedBuys] = useState(false);
  const [selectedSells, setSelectedSells] = useState(false);
  const [selectedSwaps, setSelectedSwaps] = useState(false);
  const [selectedPriceDrop, setSelectedPriceDrop] = useState(false);
  const [selectedPriceRaise, setSelectedPriceRaise] = useState(false);
  const [selectedTrackMarketCap, setSelectedTrackMarketCap] = useState(false);
  const [selectedTrackUsers, setSelectedTrackUsers] = useState(false);
  const [selectedTrackDevSelling, setSelectedTrackDevSelling] = useState(false);
  const [selectedTrackUserCreatingCoin, setSelectedTrackUserCreatingCoin] = useState(false);
  const [pairingCode, setPairingCode] = useState('');
  const [loadingPairingCode, setLoadingPairingCode] = useState(true);
  const [discordPairingCode, setDiscordPairingCode] = useState('');
  const [loadingDiscordCode, setLoadingDiscordCode] = useState(true);
  const [linkedAccounts, setLinkedAccounts] = useState({ telegramLinked: false, discordLinked: false });

  useEffect(() => {
    const fetchLinkedAccounts = async () => {
      try {
        const response = await axios.get(`${API_URL}/get-linked-accounts`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setLinkedAccounts(response.data);
      } catch (error) {
        console.error('Error fetching linked accounts:', error);
      }
    };
  
    fetchLinkedAccounts();
  }, []);

  // Fetch pairing code and Discord invite link on component mount
  useEffect(() => {
    const fetchData = async () => {
      if (selectedSendVia === 'Telegram') {
        const code = await getPairingCode();
        setPairingCode(code || 'Error fetching code');
        setLoadingPairingCode(false);
      } else if (selectedSendVia === 'Discord') {
        const code = await getDiscordPairingCode();
        setDiscordPairingCode(code || 'Error fetching code');
        setLoadingDiscordCode(false);
      }
    };

    fetchData();
  }, [selectedSendVia]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'discord-linked') {
        console.log('Discord account linked successfully');
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const handleDiscordLink = async () => {
    try {
      const clientID = process.env.REACT_APP_DISCORD_CLIENT_ID;
      const redirectUri = `${API_URL}/get-link-discord-account`;
      const discordAuthUrl = `https://discord.com/oauth2/authorize?client_id=${clientID}&permissions=0&scope=bot%20identify&response_type=code&state=${discordPairingCode}&redirect_uri=${encodeURIComponent(redirectUri)}`;
      
      const width = 800;
      const height = 1000;
      const left = (window.screen.width / 2) - (width / 2);
      const top = (window.screen.height / 2) - (height / 2);
      
      window.open(
        discordAuthUrl,
        'Discord Authorization',
        `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,status=yes`
      );
    } catch (error) {
      console.error('Error initiating Discord link:', error);
    }
  };

  const getPairingCode = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/get-pairing-code`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.pairingCode;
    } catch (error) {
      console.error('Error fetching pairing code:', error);
      return null;
    }
  };

  const getDiscordPairingCode = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/get-discord-pairing-code`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.pairingCode;
    } catch (error) {
      console.error('Error fetching Discord pairing code:', error);
      return null;
    }
  };

  const renderTelegramConnection = () => {
    if (loadingPairingCode) {
      return <p>Loading Telegram connection...</p>;
    }

    return (
      <div className="mb-4">
        <p>{linkedAccounts.telegramLinked ? 'You already have one or more Telegram accounts linked. To link another, follow these instructions:' : 'To receive notifications via Telegram, you must first connect with our bot:'}</p>
        <br />
        <ol className="list-decimal list-inside">
          <li>
            <a
              href="https://t.me/VoltexActiveBot"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Click here to open a direct message with our bot
            </a>
          </li>
          <li>Send the following pairing code to the bot: <strong>{pairingCode}</strong></li>
          <li>The bot will confirm when your account is successfully linked</li>
        </ol>
      </div>
    );
  };

  const renderDiscordConnection = () => {
    if (loadingDiscordCode) {
      return <p>Loading Discord connection...</p>;
    }

    return (
      <div className="mb-4">
        <p>{linkedAccounts.discordLinked ? 'You already have one or more Discord accounts linked. To link another, follow these instructions:' : 'To receive notifications via Discord, follow these steps:'}</p>
        <br />
        <ol className="list-decimal list-inside">
          <li>Follow the prompts to add the bot to your server or authorize it</li>
          <li>The bot will automatically link your Discord account once authorized</li>
          <button
            onClick={handleDiscordLink}
            className="bg-[#5865F2] hover:bg-[#4752C4] text-white font-bold py-2 px-4 rounded inline-flex items-center transition duration-300 ease-in-out"
          >
            <svg
              className="w-6 h-6 mr-2"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z"/>
            </svg>
            Link Discord
          </button>
        </ol>
      </div>
    );
  };



  const renderSendViaPrompt = () => {
    switch (selectedSendVia) {
      case 'SMS':
        return "Enter the mobile number you want your notifications sent to."
      case 'WhatsApp':
        return "Enter the WhatsApp Number you want your notifications sent to."
      default:
        return '';
    }
  };

  const renderConnectionLinks = () => {
    switch (selectedSendVia) {
      case 'Discord':
        return renderDiscordConnection();
      case 'Telegram':
        return renderTelegramConnection();
      case 'SMS':
        break;
      case 'WhatsApp':
        break;
      default:
        return '';
    }
  }

  const renderFilterOptions = () => {
    switch (selectedOption) {
      case 'wallet':
        return (
          <>
            <FilterCheckbox label="Buys" checked={selectedBuys} onChange={setSelectedBuys} />
            <FilterCheckbox label="Sells" checked={selectedSells} onChange={setSelectedSells} />
            <FilterCheckbox label="Swaps" checked={selectedSwaps} onChange={setSelectedSwaps} />
          </>
        );
      case 'stock':
        return (
          <>
            <FilterCheckbox label="Buys" checked={selectedBuys} onChange={setSelectedBuys} />
            <FilterCheckbox label="Sells" checked={selectedSells} onChange={setSelectedSells} />
            <FilterCheckbox label="Price Drop" checked={selectedPriceDrop} onChange={setSelectedPriceDrop} />
            <FilterCheckbox label="Price Raise" checked={selectedPriceRaise} onChange={setSelectedPriceRaise} />
          </>
        );
      case 'pumpfun':
        return (
          <>
            <FilterCheckbox label="Track Market Cap" checked={selectedTrackMarketCap} onChange={setSelectedTrackMarketCap} />
            <FilterCheckbox label="Track Users within a Coin" checked={selectedTrackUsers} onChange={setSelectedTrackUsers} />
            <FilterCheckbox label="Track Dev Selling" checked={selectedTrackDevSelling} onChange={setSelectedTrackDevSelling} />
            <FilterCheckbox label="Track User Creating a Coin" checked={setSelectedTrackUserCreatingCoin} />
          </>
        );
      default:
        return null;
    }
  };

  const handleApplyFilters = () => {
    if (!account.trim() && !(linkedAccounts.telegramLinked || linkedAccounts.discordLinked)) {
      setError('You must enter an account/number to apply filters');
      return;
    }

    setError('');
    
    const baseFilter = {
      account: account.trim(),
      sendupdates: true
    };

    let specificFilters = {};
    switch (selectedOption) {
      case 'wallet':
        specificFilters = {
          buys: selectedBuys,
          sells: selectedSells,
          swaps: selectedSwaps
        };
        break;
      case 'stock':
        specificFilters = {
          buys: selectedBuys,
          sells: selectedSells,
          pricedrop: selectedPriceDrop,
          priceraise: selectedPriceRaise
        };
        break;
      case 'pumpfun':
        specificFilters = {
          trackmarketcap: selectedTrackMarketCap,
          trackusers: selectedTrackUsers,
          trackdevselling: selectedTrackDevSelling,
          trackusercreatingcoin: selectedTrackUserCreatingCoin
        };
        break;
      default:
        break;
    }

    const filter = {
      [selectedSendVia.toLowerCase()]: [{ ...baseFilter, ...specificFilters }]
    };

    const existingFilters = JSON.parse(localStorage.getItem('filters')) || {};
    existingFilters[selectedOption] = filter;

    localStorage.setItem('filters', JSON.stringify(existingFilters));
    alert('Filters saved. You can now add the tracker with these filters.');
  };

  return (
    <div ref={ref} className="border border-gray-300 dark:border-gray-700 bg-white dark:bg-gray-900 shadow-md rounded p-4 w-80 h-100 text-center text-black dark:text-white overflow-y-auto">
      <h2 className="text-lg font-semibold mb-4">Filters</h2>
      <p className="mb-4">{renderSendViaPrompt()}</p>
      { (selectedSendVia === 'SMS' || selectedSendVia === 'WhatsApp') && (
        <input
          type="text"
          className={`w-full border rounded p-2 mb-4 bg-white dark:bg-gray-800 text-black dark:text-white ${error ? 'border-red-500' : 'border-gray-300 dark:border-gray-600'}`}
          placeholder="Enter account/number"
          value={account}
          onChange={(e) => setAccount(e.target.value)}
        />
      )}
      {error && <p className="text-red-500 text-sm">{error}</p>}
      {renderConnectionLinks()}
      <div className="flex flex-col items-start mb-4">
        {/* <FilterCheckbox
          //label={selectedOption === 'kick' || selectedOption === 'rumble' || selectedOption === 'twitch' ? "Send Live Notification" : "Send Updates"}
          checked={selectedSendUpdates}
          onChange={setSelectedSendUpdates}
        /> */}
        {renderFilterOptions()}
      </div>
      <button
        onClick={handleApplyFilters}
        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600"
      >
        Apply Filters
      </button>
    </div>
  );
});

function FilterCheckbox({ label, checked = false, onChange }) {
  return (
    <div className="flex items-center mb-2">
      <input
        type="checkbox"
        checked={!!checked}  // Ensure the checked prop is always boolean
        onChange={(e) => onChange(e.target.checked)}
        className="mr-2"
      />
      <label>{label}</label>
    </div>
  );
}

export default Filters;