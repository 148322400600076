import React, { useEffect } from 'react';

function DarkModeToggle({ darkMode, toggleDarkMode }) {
  // Ensure the correct initial state is set on mount
  useEffect(() => {
    const isDarkMode = document.documentElement.classList.contains('dark');
    if (isDarkMode !== darkMode) {
      toggleDarkMode();
    }
  }, [darkMode, toggleDarkMode]);

  return (
    <button onClick={toggleDarkMode} className="flex items-center space-x-2 text-gray-700 dark:text-gray-300">
      {darkMode ? (
        <>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 12.79A9 9 0 1111.21 3 7 7 0 0021 12.79z"
            ></path>
          </svg>
        </>
      ) : (
        <>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 3v1m0 16v1m8-9h1M3 12H2m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707M16.95 7.05l.707-.707M7.05 16.95l.707-.707M12 5a7 7 0 00-7 7m14 0a7 7 0 00-7-7m0 14a7 7 0 007-7m-14 0a7 7 0 007 7z"
            ></path>
          </svg>
        </>
      )}
    </button>
  );
}

export default DarkModeToggle;
